const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: '',
  databaseURL: '',
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: '',
  messagingSenderId: '',
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

export default firebaseConfig;